import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { MatButtonModule } from "@angular/material/button";

@Component({
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.css'],
    imports: [MatButtonModule]
})
export class ErrorMessageComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { errorMessage: string, action: string }) { }

  reload() {
    window.location.reload();
  }
}
