import { Component } from '@angular/core';
import { UpdateService } from './services/update.service';
import { RouterOutlet } from '@angular/router';
import { ReleaseNotesService } from './services/release-notes.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    imports: [RouterOutlet]
})
export class AppComponent {
  title = 'GLG.UI';
  constructor(updates: UpdateService, releaseNotes: ReleaseNotesService) {
    updates.checkForUpdates();

    // Delay a bit as the update above might reload the page...
    setTimeout(() => releaseNotes.checkReleaseNotes(), 5000);
  }
}
