import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoPipe } from '@jsverse/transloco';
import { AuthService } from 'src/app/auth.service';
import { logEvent } from 'src/app/services/logging.service';

@Component({
    selector: 'app-first-time-user-bar',
    templateUrl: './first-time-user-bar.component.html',
    styleUrls: ['./first-time-user-bar.component.css'],
    imports: [MatButtonModule, TranslocoPipe]
})
export class FirstTimeUserBarComponent {
  constructor(private authService: AuthService) { }

  @logEvent('first_time_user', 'login')
  login(): void {
    this.authService.login();
  }

  @logEvent('first_time_user', 'register')
  register(): void {
    this.authService.register();
  }
}
