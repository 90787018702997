import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActiveUserGuard } from './active-user.guard';
import { DemoComponent } from "./demo/demo.component";
import { ShellComponent } from "./shell/shell.component";
import { SignupComponent } from './signup/signup.component';

const routes: Routes = [
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  {
    path: 'app',
    component: ShellComponent,
    canActivate: [ActiveUserGuard],
    children: [
      {
        path: 'shoppinglist',
        loadChildren: () => import('./shopping-list/shopping-list.routes').then(m => m.SHOPPING_LIST_ROUTES),
        data: { animation: 'shoppinglist' }
      },
      {
        path: 'items',
        loadChildren: () => import('./items/items.routes').then(m => m.ITEMS_ROUTES),
        data: { animation: 'items' }
      },
      {
        path: 'recipes',
        loadChildren: () => import('./recipes/recipes.routes').then(m => m.RECIPES_ROUTES),
        data: { animation: 'recipes' }
      },
      {
        path: 'stores',
        loadChildren: () => import('./stores/stores.routes').then(m => m.STORES_ROUTES),
        data: { animation: 'stores' }
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.routes').then(m => m.SETTINGS_ROUTES),
        data: { animation: 'settings' }
      },
      { path: '', redirectTo: 'shoppinglist', pathMatch: 'full' }
    ]
  },
  {
    path: 'welcome',
    loadChildren: () => import('./onboard/onboard.routes').then(m => m.ONBOARD_ROUTES),
  },
  {
    path: 'demo',
    component: DemoComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
