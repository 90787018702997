import { Component, inject, OnInit } from "@angular/core";
import { AuthService } from "../auth.service";

@Component({
  selector: 'app-signup',
  imports: [],
  template: ''
})
export class SignupComponent implements OnInit {
  private readonly authService = inject(AuthService);

  ngOnInit() {
    this.authService.register();
  }
}
