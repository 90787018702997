import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslocoPipe } from '@jsverse/transloco';
import { logEvent } from '../services/logging.service';
import { SKIP_PWA_INSTALL_PROMPT_KEY } from '../shared/web-storage-keys';

@Component({
    selector: 'app-install-pwa',
    templateUrl: './install-pwa.component.html',
    styleUrls: ['./install-pwa.component.css'],
    imports: [MatToolbarModule, MatButtonModule, MatIconModule, NgIf, TranslocoPipe]
})
export class InstallPwaComponent {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: { mobileType: 'ios' | 'android', promptEvent?: { prompt: () => void } },
    private bottomSheetRef: MatBottomSheetRef<InstallPwaComponent>
  ) { }

  @logEvent('pwa', 'install')
  installPwa(): void {
    this.data.promptEvent?.prompt();
    this.close();
  }

  @logEvent('pwa', 'dont_ask_again')
  dontAskAgain(): void {
    localStorage.setItem(SKIP_PWA_INSTALL_PROMPT_KEY, 'true');
    this.bottomSheetRef.dismiss();
  }

  @logEvent('pwa', 'close')
  close(): void {
    this.bottomSheetRef.dismiss();
  }
}
