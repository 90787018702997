import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslocoPipe } from '@jsverse/transloco';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-hotkeys-dialog',
    templateUrl: './hotkeys-dialog.component.html',
    styleUrls: ['./hotkeys-dialog.component.css'],
    imports: [MatDialogTitle, MatDialogContent, NgIf, NgFor, TranslocoPipe]
})
export class HotkeysDialogComponent {

  hotkeys = Array.from(this.data)
    .map(v => [this.replaceSymbols(v[0]), v[1]]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Map<string, string>,
    private sanitizer: DomSanitizer
  ) { }

  display(input: string) {
    return this.sanitizer.bypassSecurityTrustHtml(input);
  }

  private replaceSymbols(input: string) {
    const metaSymbol = window.navigator.platform.includes('Mac')
      ? '&#8984;' // ⌘
      : '&#x229e; Win';

    return input
      .replace(/\./g, '+')
      .replace('meta', metaSymbol)
      .replace('shift', '&#8679;') // ⇧
      .replace('left', '&#8592;') // ←
      .replace('right', '&#8594;') // →
      .replace('up', '&#8593;') // ↑
      .replace('down', '&#8595;)'); // ↓
  }


}
